<template>
  <div class="py-8 px-5 lg:px-0">
    <nuxt-img loading="lazy" class="xl:h-7" src="/footer_logo.svg" alt="Schilderaufsteller" />
    <hr class="mt-7 mb-5 rounded-10" />
    <div
      class="sm:flex sm:flex-row sm:items-center sm:space-x-7 space-y-7 sm:space-y-0 justify-between text-white font-semibold flex flex-col-reverse"
    >
      <p class="mt-7 sm:mt-0 font-normal text-xs">
        © {{ new Date().getFullYear() }} Schilderaufsteller.de | Made with 🚀 by <a target="_blank" href="https://kommers.io">KOMMERS</a>
      </p>
      <div class="inline-grid sm:flex text-xs justify-between sm:items-center sm:space-x-7 space-y-7 sm:space-y-0">
        <a
          href="https://www.schilderaufsteller.de/impressum"
          target="_blank"
          class="cursor-pointer hover:text-primary transition-all duration-300 ease-in"
        >
          {{ $t('footer.imprint') }}
        </a>
        <a
          href="https://www.schilderaufsteller.de/datenschutzerklaerung"
          target="_blank"
          class="cursor-pointer hover:text-primary transition-all duration-300 ease-in"
        >
          {{ $t('footer.privacy') }}
        </a>
        <a
          href="https://www.schilderaufsteller.de/quellenangaben"
          target="_blank"
          class="cursor-pointer hover:text-primary transition-all duration-300 ease-in"
        >
          {{ $t('footer.sources') }}
        </a>
        <a href="https://www.schilderaufsteller.de/agb" target="_blank" class="cursor-pointer hover:text-primary transition-all duration-300 ease-in">
          {{ $t('footer.conditions') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PartFooter'
}
</script>
