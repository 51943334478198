import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_w2VlvAEcpa from "/vercel/path0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_client_LcKgStRyi6 from "/vercel/path0/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_0xMyHWTTaa from "/vercel/path0/node_modules/@ambitiondev/nuxt-cookiebot/dist/runtime/plugin.mjs";
import plugin_FRmGFsEaPh from "/vercel/path0/.nuxt/plugin.mjs";
import i18n_wvJtu1yLPy from "/vercel/path0/node_modules/@nuxtjs/i18n-edge/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import vue_aw_client_mRNUgZGTm0 from "/vercel/path0/plugins/vue-aw.client.ts";
import vue_gtag_client_CYt6toK02h from "/vercel/path0/plugins/vue-gtag.client.ts";
import vueQuery_HbXfDZmQp2 from "/vercel/path0/plugins/vueQuery.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_w2VlvAEcpa,
  plugin_client_LcKgStRyi6,
  plugin_0xMyHWTTaa,
  plugin_FRmGFsEaPh,
  i18n_wvJtu1yLPy,
  chunk_reload_client_UciE0i6zes,
  vue_aw_client_mRNUgZGTm0,
  vue_gtag_client_CYt6toK02h,
  vueQuery_HbXfDZmQp2
]