<template>
  <div class="font-body" style="background: url('/halteverbotszone-hamburg-xl.jpg') no-repeat center center fixed; background-size: cover;">
    <div class="relative flex flex-col pb-10 min-h-[85vh]">
      <div class="z-10 mx-5 lg:mx-0">
        <div class="lg:px-8 xl:px-48" style="max-width: 1920px; margin: 0 auto">
          <parts-navbar />
          <NuxtPage />
        </div>
      </div>
    </div>
    <div class="bg-secondary">
      <div class="xl:px-48 lg:px-8 w-full" style="max-width: 1920px; margin: 0 auto">
        <parts-footer />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useCartStore } from './store/cart'
const title = ref('Schilderaufsteller.de - Halteverbote')
const description = ref(
  'Halteverbote mit polizeilicher Genehmigung. Jetzt sorgenfrei die Halteverbotszone deutschlandweit inkl. Schilder online bestellen.'
)

const cartStore = useCartStore()
cartStore.loadFromLocalStorage()

useHead({
  title,
  meta: [
    {
      name: 'description',
      content: description
    },
    {
      name: 'robots',
      content: 'noindex'
    }
  ],
  htmlAttrs: {
    lang: 'de'
  }
})
</script>
