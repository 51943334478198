import { defineNuxtPlugin, useRouter } from 'nuxt/app'
import VueGtag, { trackRouter } from 'vue-gtag-next'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(VueGtag, {
    property: {
      id: 'G-1BQ1JFDV5H'
    }
  })
  trackRouter(useRouter())
})
