import { defineStore } from 'pinia'
import { Period } from '~/domain/Period'
import { Permits } from '~/domain/Permits'
import { PrivacyType } from '~/domain/PrivacyType'
import { ProcessingTime } from '~/domain/ProcessingTime'
import { ServiceType } from '~/domain/ServiceType'
import { TimeSpanOption } from '~/domain/TimeSpanOption'

export const useBookingStore = defineStore('booking', {
  state: () => ({
    selectedService: null as null | ServiceType,
    normal_service_time: 0,
    express_service_time: 0,
    serviceId: null as null | string,
    district: '',
    prices: {
      base_service_price: 0,
      full_service_price: 0,
      express_service_price: 0,
      base_service_price_both_sides: 0,
      full_service_price_both_sides: 0,
      oneSideTax: 0,
      bothSidesTax: 0
    },
    information: {
      shippingAddress: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        street: '',
        houseNumber: '',
        postCode: '',
        city: ''
      },
      billingAddress: {
        companyName: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        street: '',
        houseNumber: '',
        postCode: '',
        city: ''
      },
      differentBillingAddress: false
    },
    options: {
      timeSpan: TimeSpanOption.EACH_DAY,
      processingTime: ProcessingTime.NORMAL,
      permits: Permits.SELF_SERVICE,
      period: Period.ONE_DAY,
      movingType: PrivacyType.PRIVATE,
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      endDateEndTime: '',
      sitePlan: [],
      additionalFiles: [],
      lengthOfTheForbiddenZone: 10,
      bothSidesRequired: false,
      pedestrianZone: false,
      extraInformation: '',
      reasonInformation: '',
      policePermit: [],
      shippingCompanyInformation: ''
    }
  }),
  getters: {
    getSelectedService(state) {
      return state.selectedService
    },
    getNormalProcessingTime(state) {
      return state.normal_service_time
    },
    getExpressProcessingTime(state) {
      return state.express_service_time
    },
    getPrices(state) {
      return state.prices
    },
    getDistrict(state) {
      return state.district
    },
    getInformation(state) {
      return state.information
    },
    getOptions(state) {
      return state.options
    }
  },
  actions: {
    setSelectedService(value: ServiceType) {
      this.selectedService = value
    },
    setDistrict(value) {
      this.district = value
    },
    setPrices(value) {
      this.prices = value
    },
    setInformation(value) {
      this.information = value
    },
    setOptions(value) {
      this.options = value
    },
    reset() {
      this.selectedService = null
      this.district = ''
      this.options = {
        timeSpan: TimeSpanOption.START_TO_END,
        permits: Permits.SELF_SERVICE,
        period: Period.ONE_DAY,
        movingType: PrivacyType.PRIVATE,
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
        processingTime: ProcessingTime.NORMAL,
        endDateEndTime: '',
        shippingCompanyInformation: '',
        sitePlan: [],
        additionalFiles: [],
        lengthOfTheForbiddenZone: 10,
        bothSidesRequired: false,
        pedestrianZone: false,
        extraInformation: '',
        reasonInformation: '',
        policePermit: []
      }
    },
    loadFromLocalStorage() {
      const persistedBooking = localStorage.getItem('booking')
      if (!persistedBooking) return
      const booking = JSON.parse(persistedBooking)
      if (booking.selectedService) {
        this.setSelectedService(booking.selectedService)
      }
      if (booking.information) {
        this.setInformation(booking.information)
      }
      if (booking.options) {
        this.setOptions(booking.options)
      }
    },
    clearLocalStorage() {
      localStorage.removeItem('booking')
    },
    resetInformation() {
      this.information = {
        shippingAddress: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          street: '',
          houseNumber: '',
          postCode: '',
          city: ''
        },
        billingAddress: {
          companyName: '',
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          street: '',
          houseNumber: '',
          postCode: '',
          city: ''
        },
        differentBillingAddress: false
      }
    }
  }
})
