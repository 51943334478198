import { defineStore } from 'pinia'
import { Period } from '~/domain/Period'
import { Permits } from '~/domain/Permits'
import { PrivacyType } from '~/domain/PrivacyType'
import { ProcessingTime } from '~/domain/ProcessingTime'
import { ServiceType } from '~/domain/ServiceType'

export interface CartService {
  serviceType: ServiceType
  information: {
    shippingAddress: CartAddress
    differentBillingAddress: boolean
  }
  options: {
    processingTime: ProcessingTime
    permits: Permits
    period: Period
    movingType: PrivacyType
    startDate: string
    startTime: string
    endDate: string
    endTime: string
    endDateEndTime: string
    additionalFiles: []
    lengthOfTheForbiddenZone: number
    bothSidesRequired: boolean
    pedestrianZone: boolean
    extraInformation: string
    reasonInformation: string
    policePermit: any[]
    shippingCompanyInformation: string
  }
  quantity: number
  serviceId: string
}

interface CartAddress {
  firstName: string
  lastName: string
  email: string
  phone: string
  street: string
  houseNumber: string
  postCode: string
  city: string
  companyName?: string
}

export interface Cart {
  services: CartService[]
  billingAddress: CartAddress
}

export const useCartStore = defineStore('cart', {
  state: () =>
    ({
      cart: {
        billingAddress: {
          city: '',
          companyName: '',
          email: '',
          firstName: '',
          houseNumber: '',
          lastName: '',
          phone: '',
          postCode: '',
          street: ''
        },
        services: []
      }
    }) as { cart: Cart },
  getters: {
    getCart(state) {
      return state.cart
    }
  },
  actions: {
    async setCart(value: Cart) {
      this.cart = value
    },
    incrementQuantity(serviceId: string) {
      if (!this.cart) return
      this.setCart({
        ...this.cart,
        services: this.cart.services.map((service) => {
          if (service.serviceId === serviceId) {
            return {
              ...service,
              quantity: service.quantity + 1
            }
          }
          return service
        })
      })
    },
    decrementQuantity(serviceId: string) {
      this.setCart({
        ...this.cart,
        services: this.cart.services.map((service) => {
          if (service.serviceId === serviceId) {
            return {
              ...service,
              quantity: service.quantity - 1
            }
          }
          return service
        })
      })
    },
    removeService(serviceId: string) {
      this.setCart({
        ...this.cart,
        services: this.cart.services.filter((service) => {
          return service.serviceId !== serviceId
        })
      })
    },
    loadFromLocalStorage() {
      const persistedCart = localStorage.getItem('cart')
      if (persistedCart) {
        const cart = JSON.parse(persistedCart)
        this.setCart(cart)
      }
    }
  }
})
