import { default as cart3AXNnOUus9Meta } from "/vercel/path0/pages/cart.vue?macro=true";
import { default as checkoutlZQLvi17ODMeta } from "/vercel/path0/pages/checkout.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
export default [
  {
    name: cart3AXNnOUus9Meta?.name ?? "cart",
    path: cart3AXNnOUus9Meta?.path ?? "/cart",
    meta: cart3AXNnOUus9Meta || {},
    alias: cart3AXNnOUus9Meta?.alias || [],
    redirect: cart3AXNnOUus9Meta?.redirect,
    component: () => import("/vercel/path0/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: checkoutlZQLvi17ODMeta?.name ?? "checkout",
    path: checkoutlZQLvi17ODMeta?.path ?? "/checkout",
    meta: checkoutlZQLvi17ODMeta || {},
    alias: checkoutlZQLvi17ODMeta?.alias || [],
    redirect: checkoutlZQLvi17ODMeta?.redirect,
    component: () => import("/vercel/path0/pages/checkout.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  }
]