import {} from 'mapbox-gl'
import { defineStore } from 'pinia'

export const useMapboxStore = defineStore('mapbox', {
  state: () => {
    const shippingLocationString = localStorage.getItem('shippingLocation')
    const billingLocationString = localStorage.getItem('billingLocation')
    return {
      shippingLocation: shippingLocationString ? JSON.parse(shippingLocationString) : ({} as any),
      billingLocation: billingLocationString ? JSON.parse(billingLocationString) : ({} as any)
    }
  },
  getters: {
    getShippingLocation(state) {
      return state.shippingLocation
    },
    getBillingLocation(state) {
      return state.billingLocation
    }
  },
  actions: {
    setShippingLocation(value: any) {
      this.shippingLocation = value
      localStorage.setItem('shippingLocation', JSON.stringify(value))
    },
    setBillingLocation(value: any) {
      this.billingLocation = value
      localStorage.setItem('billingLocation', JSON.stringify(value))
    },
    reset() {
      localStorage.removeItem('shippingLocation')
      localStorage.removeItem('billingLocation')
      this.shippingLocation = {} as any
      this.billingLocation = {} as any
    }
  }
})
