import { defineStore } from 'pinia'

export const useModalStore = defineStore('modal', {
  state: () => ({
    displayModal: false
  }),
  getters: {
    getDisplayModal(state) {
      return state.displayModal
    }
  },
  actions: {
    setDisplayModal(value) {
      this.displayModal = value
    }
  }
})
