<template>
  <div
    class="bg-white rounded-10 xl:px-7.5 xl:py-6 px-5 py-8 shadow-greenShadow flex justify-between items-center xl:my-8 my-10 relative"
    :class="modalStore.displayModal && !displayBurgerMenu ? 'z-30' : 'z-40'"
  >
    <nuxt-link to="https://schilderaufsteller.de" target="_blank" @click="closeBooking(), modalStore.setDisplayModal(false)">
      <nuxt-img loading="lazy" class="hidden lg:block cursor-pointer lg:h-7" src="/navbar_logo.svg" alt="Schilderaufsteller" />
      <div class="flex items-center space-x-5 cursor-pointer">
        <nuxt-img loading="lazy" class="lg:hidden" src="/logo_icon.svg" alt="Schilderaufsteller Logo" />
        <nuxt-img
          class="lg:hidden cursor-pointer"
          style="width: 18px; height: 18px"
          src="/burger_menu.svg"
          alt="Schilderaufsteller_logo"
          @click="displayBurgerMenu = true"
        />
      </div>
    </nuxt-link>
    <!-- modal -->
    <div v-if="displayBurgerMenu" class="h-screen w-screen backdrop-blur-md bg-black opacity-30 z-40 fixed top-0 left-0"></div>
    <!-- burger menu -->
    <div
      v-if="displayBurgerMenu"
      class="z-40 lg:hidden absolute top-0 left-0 w-full rounded-10 shadow-greenShadow xl:my-8"
      style="background: transparent linear-gradient(90deg, #3dce3e 0%, #3ece3e 100%) 0% 0% no-repeat padding-box"
    >
      <div class="flex justify-between items-center xl:px-7.5 xl:py-6 px-5 py-8">
        <nuxt-img loading="lazy" class="lg:hidden" src="/logo_icon_white.svg" alt="Schilderaufsteller Logo" />
        <nuxt-img loading="lazy" class="lg:hidden" src="/close_white_24dp.svg" alt="Schilderaufsteller Logo" @click="displayBurgerMenu = false" />
      </div>
      <div class="bg-white rounded-b-10 xl:px-7.5 xl:py-6 px-5 py-8">
        <div class="flex flex-col space-y-5 font-semibold">
          <a
            href="https://www.schilderaufsteller.de/halteverbot-umzug-hamburg"
            target="_blank"
            class="cursor-pointer hover:text-primary transition-all duration-300 ease-in"
          >
            {{ $t('home.noStopping') }}
          </a>
          <div
            class="flex flex-col items-start justify-center cursor-pointer group"
            @mouseenter="isDropdownOpen = true"
            @mouseleave="isDropdownOpen = false"
          >
            <div class="flex items-center justify-start space-x-2">
              <div class="group-hover:text-primary transition-all duration-300 ease-in">
                {{ $t('header.trafficInsurance') }}
              </div>
              <svg
                class="group-hover:text-primary group-hover:rotate-180 transition-all duration-300 ease-in"
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="currentColor"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
              </svg>
            </div>
            <div class="w-full relative">
              <div v-if="isDropdownOpen" class="absolute space-y-2 bg-white rounded top-0 shadow flex flex-col justify-center items-start p-5 z-50">
                <a class="hover:text-primary transition-all duration-300 ease-in" href="https://www.schilderaufsteller.de/regelplan"
                  >Regelplan-Erstellung</a
                >
                <a
                  class="hover:text-primary transition-all duration-300 ease-in"
                  href="https://www.schilderaufsteller.de/baustellenabsicherung-hamburg"
                  >Baustellenabsicherung</a
                >
                <a class="hover:text-primary transition-all duration-300 ease-in" href="https://www.schilderaufsteller.de/verkehrssicherung-hamburg"
                  >Events & Filmdrehs</a
                >
              </div>
            </div>
          </div>
          <a
            href="https://www.schilderaufsteller.de/jobs"
            target="_blank"
            class="cursor-pointer hover:text-primary transition-all duration-300 ease-in"
          >
            Jobs
          </a>
          <a
            href="https://www.schilderaufsteller.de/kontakt"
            target="_blank"
            class="cursor-pointer hover:text-primary transition-all duration-300 ease-in"
          >
            {{ $t('header.contact') }}
          </a>
        </div>
      </div>
    </div>

    <div class="flex items-center space-x-7">
      <div class="hidden lg:flex justify-between items-center space-x-7 text-secondary font-bold lg:text-sm">
        <a
          href="https://www.schilderaufsteller.de/halteverbot-umzug-hamburg"
          target="_blank"
          class="cursor-pointer hover:text-primary transition-all duration-300 ease-in"
        >
          {{ $t('home.noStopping') }}
        </a>
        <div
          class="flex flex-col items-start justify-center cursor-pointer group"
          @mouseenter="isDropdownOpen = true"
          @mouseleave="isDropdownOpen = false"
        >
          <div class="flex items-center justify-start space-x-2">
            <div class="group-hover:text-primary transition-all duration-300 ease-in">
              {{ $t('header.trafficInsurance') }}
            </div>
            <svg
              class="group-hover:text-primary group-hover:rotate-180 transition-all duration-300 ease-in"
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="currentColor"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
            </svg>
          </div>
          <div class="w-full relative">
            <div v-if="isDropdownOpen" class="absolute space-y-2 bg-white rounded top-0 shadow flex flex-col justify-center items-start p-5 z-50">
              <a class="hover:text-primary transition-all duration-300 ease-in" href="https://www.schilderaufsteller.de/regelplan" target="_blank"
                >Regelplan-Erstellung</a
              >
              <a
                class="hover:text-primary transition-all duration-300 ease-in"
                href="https://www.schilderaufsteller.de/baustellenabsicherung-hamburg"
                target="_blank"
                >Baustellenabsicherung</a
              >
              <a
                class="hover:text-primary transition-all duration-300 ease-in"
                href="https://www.schilderaufsteller.de/verkehrssicherung-hamburg"
                target="_blank"
                >Events & Filmdrehs</a
              >
            </div>
          </div>
        </div>
        <a
          href="https://www.schilderaufsteller.de/jobs"
          target="_blank"
          class="cursor-pointer hover:text-primary transition-all duration-300 ease-in"
        >
          Jobs
        </a>
        <a
          href="https://www.schilderaufsteller.de/kontakt"
          target="_blank"
          class="cursor-pointer hover:text-primary transition-all duration-300 ease-in"
        >
          {{ $t('header.contact') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useRouter } from 'nuxt/app'
import { useModalStore } from '../../store/modal'
import { useBookingStore } from '../../store/booking'
import { useMapboxStore } from '~/store/mapbox'

const displayBurgerMenu = ref(false)
const isDropdownOpen = ref(false)
const bookingStore = useBookingStore()

const modalStore = useModalStore()
const router = useRouter()
const mapboxStore = useMapboxStore()

const place = ref<any>({})

function closeBooking() {
  bookingStore.reset()
  bookingStore.clearLocalStorage()
  mapboxStore.reset()
  place.value = {}

  router.push('/')
}
</script>
